<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        :loading="loading"
        @click="clickPdf"
        v-bind="attrs && $attrs"
        v-on="on"
      >
        <v-icon>fas fa-file-pdf</v-icon>
      </v-btn>
    </template>
    <span>Export as pdf</span>
  </v-tooltip>
</template>

<script>
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import PDF from "@/mixins/PDF";
// import "@/assets/fonts/NormalFont-normal";
// import "@/assets/fonts/BoldFont-bold";
// import { dateFormat, moneyFormat, processMoney } from "@/functions";
export default {
  mixins: [PDF],
  name: "export_to_pdf_btn",
  data() {
    return {
      loading: false,
      doc: null
    };
  },
  methods: {
    async clickPdf() {
      if (this.clickable) {
        this.$emit("click");
        return;
      }
      this.loading = true;
      try {
        this.doc = new jsPDF("p", "px", "A4");
        if (this.auto_table) {
          this.printAutoTable();
        } else if (this.html_element || this.custom_id) {
          await this.printScreenShot();
        }
        this.doc.save(this.$options.filters.fileName(this.name));
      } catch (e) {
        this.$store.commit("error", e);
      }
      this.doc = null;
      this.loading = false;
    },
    printScreenShot() {
      let x = window.scrollX,
        y = window.scrollY;
      window.scrollTo(0, 0);
      return new Promise(resolve => {
        setTimeout(async () => {
          let canvas = null,
            el = null;
          if (this.html_element) {
            el = this.html_element;
          } else {
            el = document.getElementById(this.custom_id);
          }
          canvas = await html2canvas(el);
          const pageWidth = this.doc.internal.pageSize.getWidth();
          const pageHeight = this.doc.internal.pageSize.getHeight();
          const widthRatio = pageWidth / canvas.width;
          const heightRatio =
            canvas.height > pageHeight
              ? canvas.height / pageHeight
              : pageHeight / canvas.height;
          const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
          const canvasWidth = canvas.width * ratio;
          const canvasHeight = canvas.height * ratio;
          const marginX = Math.ceil(
            Math.abs(
              pageWidth > canvasWidth
                ? (pageWidth - canvasWidth) * 2
                : (canvasWidth - pageWidth) * 2
            )
          );
          const marginY = 0;
          if (canvas.height > pageHeight * 2) {
            const cHeight = canvas.height;
            const cWidth = canvas.width;
            const imageElement = document.createElement("img");
            const pages = Math.floor(cHeight / pageHeight);
            // console.log(cHeight, pageHeight);
            // console.log(canvas.toDataURL("image/png", 1.0));
            const options = {
              sx: 0,
              sy: 0,
              swidth: cWidth,
              sheight: Math.round(pageHeight * 1.75),
              x: 0,
              y: 0,
              width: pageWidth,
              height: cHeight
            };
            imageElement.src = canvas.toDataURL("image/png", 1.0);
            for (let i = 0; i < pages; i++) {
              if (canvas && canvas.getContext) {
                let newCanvas = document.createElement("canvas");
                newCanvas.width = pageWidth / 2;
                newCanvas.height = pageHeight / 2;
                let context = newCanvas.getContext("2d");
                options.sy = pageHeight * i;
                options.height = cHeight - pageHeight * i;
                if (i === pages - 1) {
                  options.sheight = cHeight - Math.round(pageHeight * 2) * i;
                }
                // console.log(cHeight, options.sy);
                context.drawImage(
                  canvas,
                  options.sx,
                  options.sy,
                  options.swidth,
                  options.sheight,
                  options.x,
                  options.y,
                  options.width,
                  options.height
                );
                if (i !== 0) this.doc.addPage("A4", "portrait");
                this.doc.addImage(
                  newCanvas.toDataURL("image/png", 1.0),
                  "JPEG",
                  marginX,
                  marginY,
                  pageWidth - marginX,
                  pageHeight
                );
                // console.log();
              }
            }
          } else {
            this.doc.addImage(
              canvas.toDataURL("image/png", 1.0),
              "JPEG",
              marginX,
              marginY,
              canvasWidth - marginX,
              canvasHeight
            );
          }
          window.scrollTo(x, y);
          resolve(this.doc);
        }, 1000);
      });
    },
    printAutoTable() {
      let body = [],
        headers = [];
      this.data.forEach((employee, dataIndex) => {
        let data = [];
        if (dataIndex === 0) {
          headers.push("#");
        }
        this.labels.forEach((label, index) => {
          if (index === 0) {
            data.push(dataIndex + 1);
          }
          data.push(employee[label["field"]]);
          if (!headers.includes(label["headerName"])) {
            headers.push(label["headerName"]);
          }
        });
        body.push(data);
      });
      console.log(this.doc);
      console.log(headers);
      autoTable(this.doc, {
        head: [headers],
        body
      });
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: "test"
    },
    html_element: {
      default: false
    },
    custom_id: {
      type: String,
      default: null
    },
    clickable: {
      type: Boolean,
      default: false
    },
    auto_table: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    jsPDF.API.myRect = function(x, y, w, h, style, options = {}) {
      if (options.align === "center") {
        var pageWidth = this.internal.pageSize.width;
        x = (pageWidth - w) / 2;
      }
      if (options.rx || options.ry) {
        this.roundedRect(x, y, w, h, options.rx || 0, options.ry || 0, style);
      } else {
        this.rect(x, y, w, h, style);
      }
    };
    jsPDF.API.myText = function(txt, options, x, y) {
      options = options || {};
      if (options.align == "center") {
        // Get current font size
        var fontSize = this.internal.getFontSize();

        // Get page width
        var pageWidth = this.internal.pageSize.width;

        // Get the actual text's width
        /* You multiply the unit width of your string by your font size and divide
         * by the internal scale factor. The division is necessary
         * for the case where you use units other than 'pt' in the constructor
         * of jsPDF.
         */
        const txtWidth =
          (this.getStringUnitWidth(txt) * fontSize) / this.internal.scaleFactor;

        // Calculate text's x coordinate
        x = (pageWidth - txtWidth) / 2;
      }
      this.text(txt, x, y);
    };
  }
};
</script>

<style>
#promoted {
  position: fixed;
  top: 0;
}
</style>
